<template>
  <div class="view">
    <div class="main">
      <Nav @finishInquiry="finishInquiry" :inquiryName="inquiryName" />
      <InputView
        v-if="chat_type_temp != 1"
        :userMessage="userMessage"
        @receiveMessage="receiveMessage"
        :inquiryInfo="inquiryInfo"
      />
      <rightCot v-if="chat_type_temp != 1" :inquiryInfo="inquiryInfo" />
      <Video v-if="chat_type_temp == 1" :inquiryInfo="inquiryInfo" />
      <Chat 
         v-if="chat_type_temp == 1"
        :userMessage="userMessage"
        @receiveMessage="receiveMessage"
        :inquiryInfo="inquiryInfo"
      />
    </div>
  </div>
</template>

<script>
import Nav from "./nav";
import InputView from "./InputView";
import rightCot from "./rightCot";
import Video from "./video";
import Chat from "./chat"
export default {
  props: ["userMessage", "inquiry_Info", "inquiryName", "chat_type"],
  components: {
    Nav,
    InputView,
    rightCot,
    Video,
    Chat
  },
  data() {
    return {
      chat_type_temp: 0,
      inquiryInfo: {}
    };
  },
  created() {
    this.chat_type_temp = this.chat_type;
    this.inquiryInfo = this.inquiry_Info;
  },
  methods: {
    receiveMessage(msg) {
      this.$emit("sendMessage", msg);
    },
    finishInquiry() {
      this.$emit("changeOnline", "finish");
    }
  }
};
</script>

<style scoped>
.main {
  position: fixed;
  min-width: 1200px;
  width: 100%;
  height: calc(100% - 0px);
}
</style>