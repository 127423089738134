<template>
	<div>
		<Header v-if="isLandHeader" />
		<div v-if="isLandHeader">
			<LandHeader nameType="药师端" @changeState="changeState" />
			<div class="banner" v-if="state === 0">
				<el-carousel height="512px">
					<el-carousel-item v-for="item in banner_List" :key="item">
						<img :src="item.image" alt />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="wrap">
				<!-- 工作台 -->
				<Workbench v-if="state === 0" :chat_type="chat_type" :auditNums="auditNums" :waiting_num="waiting_num" @changeState="changeState"
				 @changeOnline="changeOnline" />
				<!-- 药师审方 -->
				<TiralParty v-if="state === 1" @changeState="changeState" />
				<!-- 药师审方 > 处方信息 -->
				<DisposeInfo v-if="state === 2" :pCode="pCode" @changeState="changeState" />
				<!-- 审核不通过理由 -->
				<NoAdopt v-if="state === 3" :pCode="pCode" :imgUrl="imgUrl" @changeState="changeState" />
			</div>
			<Footer />
		</div>
		<div v-else>
			<!-- 问诊界面  -->
			<OnlineInquiry :userMessage="userMessage" :chat_type="chat_type" @changeOnline="changeOnline" @sendMessage="sendMessage"
			 :inquiry_Info="inquiryInfo" :inquiryName="inquiryName" />
		</div>
	</div>
</template>

<script>
	import Header from "@/components/home_repect/header-top.vue";
	import Footer from "@/components/public/public_bottom.vue";
	import LandHeader from "@/components/home_repect/header.vue";
	import Workbench from "./workbench";
	import TiralParty from "./trialParty";
	import DisposeInfo from "./disposeInfo";
	import OnlineInquiry from "./online_inquiry/main";
	import NoAdopt from "./noAdopt";

	// 获取用户信息
	import {
		usergetinfo
	} from "@/request/user";
	// 获取轮播列表
	import {
		banner
	} from "@/request/index";

	import Vue from "vue";
	export default {
		data() {
			return {
				state: 0,
				isLandHeader: true, // 当进入问诊聊天界面时不显示
				userMessage: {},
				waiting_num: 0, // 当前等待人数
				user: {}, // 存储用户信息
				auditNums: 0,
				disposeInfo: {}, // 处方详细信息
				pCode: "", // 处方单编号
				imgUrl: "", //签名照片
				banner_List: [], // 处方图片
				userInfo: "", // 用户详细信息
				inquiryInfo: {},
				inquiryName: "",
				isAcceptInquiry: true, // 是否接受问诊
				reason: "", // 拒绝问诊原因
				chat_type: 0,
				inquiry_doctor_id: "",
				inquiry_id: "",
				patient_uid: ""
			};
		},
		components: {
			Header,
			Footer,
			LandHeader,
			Workbench,
			TiralParty,
			DisposeInfo,
			OnlineInquiry,
			NoAdopt
		},
		created() {

			Vue.prototype.$token = localStorage.getItem("lzyyToken");

			// 医生进入首页就连接socket
			if (!this.$store.state.isLogin) {
				this.initWebSocket();
			} else {
				this.websocketsend = this.$store.state.sendMessage;
				this.$store.state.websock.onmessage = this.websocketonmessage;
			}
			// 获取个人信息
			usergetinfo({
				token: this.$token
			}).then(res => {
				this.userinfo = res.data;
			});

			// 获取图片列表
			banner({
				position_id: 1
			}).then(res => {
				this.banner_List = res.data;
			});
			this.$store.state.isLogin = true;

			window.addEventListener("beforeunload", e => this.beforeunloadHandler(e));

		},
		beforeDestroy() {
			// 离开首页关闭socket
			// this.websocketclose();
		},
		methods: {
			changeState(num, pCode, imgUrl) {
				this.state = num;
				// 需要把处方单详情给传递过去
				if (num == 2 || num == 3) {
					this.pCode = pCode;
					this.imgUrl = imgUrl;
				}
			},
			changeOnline(e, reason) {
				// 结束了问诊
				if (e == "finish") {
					this.finishInquiry();
					this.isLandHeader = true;
					return;
				}
				if (e) {
					// 接受了问诊
					this.isAcceptInquiry = true;
					this.isLandHeader = false;
					this.acceptInquiry();
				} else {
					// 拒绝了问诊
					this.reason = reason;
					this.isAcceptInquiry = false;
					this.refuseInquiry();
				}
			},
			initWebSocket() {
				//初始化websocket
				console.log("初始化socket");
				const wsuri = "wss://hlwyaodian.jzbwlkj.com/wss"
				this.websock = new WebSocket(wsuri);
				// onopen 建立连接成功后执行的方法
				this.websock.onopen = this.websocketonopen;
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onerror = this.websocketonerror;
				this.websock.onclose = this.websocketclose;
				this.$store.state.sendMessage = this.websocketsend;
				this.$store.state.websock = this.websock;
				// 心跳
				setInterval(() => {
					this.websocketsend({
						method: "ping"
					});
				}, 10000);
			},
			websocketonopen() {
				// 连接建立之后执行send方法发送数据
				// this.websocketsend();
				// this.userlogin();
			},
			websocketonerror() {
				// 连接建立失败重连
				this.initWebSocket();
			},
			websocketonmessage(e) {

				// 数据接收
				const redata = JSON.parse(e.data);
				console.log(redata);
				// 更新等待人数
				if (redata.result) {
					this.waiting_num = redata.result.waiting_num;
					if (redata.result.prescription_num) {
						this.auditNums = redata.result.prescription_num;
					}
				}
				if (redata.method == "sys") {
					this.userlogin();
					// 获取问诊患者的详细信息
					this.timer = setInterval(() => {
						this.getWaitingInfo();
					}, 3000);
				}

				if (redata.method == "get_waiting") {
					this.inquiryInfo = redata.result.inquiry_info;
					// console.log(inqui)
					this.chat_type = this.inquiryInfo.chat_type;
				}

				if (redata.method == "doctor_connect") {
					if (redata.result.chat_status == 5) return;
					this.chatIn();
				}

				// 消息传递过来，且不是空信息。 信息筛选
				if (
					redata.method == "chat_message" ||
					redata.method == "chat_in" ||
					redata.method == "chat_close"
				) {
					this.selectInfo(redata);
				}

				if (redata.method == "chat_transfer_category") {
					this.transferDoctor(redata.result);
				}
			},
			userlogin() {
				let actions = {
					method: "login",
					token: this.$token
				};
				this.websocketsend(JSON.stringify(actions));
			},
			getWaitingInfo() {
				// 获取待问诊患者的详细信息
				let getWaiting = {
					method: "get_waiting"
				};
				this.websocketsend(JSON.stringify(getWaiting));
			},
			acceptInquiry() {
				// 药师接受问诊时触发
				this.inquiry_doctor_id = this.inquiryInfo.inquiry_doctor_id;
				this.inquiry_id = this.inquiryInfo.id;
				this.patient_uid = this.inquiryInfo.patient_uid;
				let set_inquiry = {
					method: "set_inquiry",
					data: {
						inquiry_doctor_id: this.inquiry_doctor_id, // 药师问诊记录id
						chat_status: 2
					}
				};
				this.websocketsend(JSON.stringify(set_inquiry));
			},
			refuseInquiry() {
				let set_inquiry = {
					method: "set_inquiry",
					data: {
						inquiry_doctor_id: this.inquiryInfo.inquiry_doctor_id,
						chat_status: 5,
						remark: this.reason
					}
				};
				this.websocketsend(JSON.stringify(set_inquiry));
			},
			chatIn() {
				// 药师进入聊天室，紧接着触发
				let chat_in = {
					method: "chat_in",
					data: {
						inquiry_doctor_id: this.inquiry_doctor_id,
						inquiry_id: this.inquiry_id
					}
				};
				this.websocketsend(JSON.stringify(chat_in));
			},
			sendMessage(msg) {
				// 代理子组件发送消息
				let chat_message = {
					method: "chat_message",
					data: {
						inquiry_id: this.inquiry_id,
						inquiry_doctor_id: this.inquiry_doctor_id,
						to_uid: this.patient_uid,
						[msg.includes("http://hlwyaodian.jzbwlkj.com") ?
							"send_img" :
							"send_text"
						]: msg
					}
				};

				this.websocketsend(JSON.stringify(chat_message));
			},
			finishInquiry() {
				let endInquiry = {
					method: "chat_close",
					data: {
						inquiry_id: this.inquiry_id,
						inquiry_doctor_id: this.inquiry_doctor_id
					}
				};
				this.websocketsend(JSON.stringify(endInquiry));
			},
			websocketsend(Data) {
				this.websock.send(Data);
			},
			websocketclose(e) {
				console.log("断开连接", e);
			},
			beforeunloadHandler() {
				this.websocketclose();
			},
			selectInfo(redata) {
				// 筛选信息
				if (redata.method == "chat_in") {
					if (redata.result.user_info.cert_type == 0) {
						this.userMessage = redata;
						this.inquiryName = redata.result.user_info.username;
					}
				} else if (redata.method == "chat_close") {
					this.userMessage = redata;
				} else {
					if (redata.result.user_info.user_id == this.patient_uid) {
						this.userMessage = redata;
					}
					if (redata.result.message_type == "sys_notice") {
						if (redata.result.message == "当前咨询科目暂无在线医生") {
							this.$message({
								type: "error",
								message: redata.result.message
							});
							return;
						}
						this.userMessage = redata;
					}
					if (redata.result.message_type == "sys_doctor") {
						return;
					}
				}
			},
			transferDoctor(result) {
				// 转接医生
				if (result.category_list[0] && result.category_list[0].pid == 0) {
					this.$store.state.department1 = result.category_list;
				}
				if (result.category_list[0] && result.category_list[0].pid != 0) {
					this.$store.state.department2 = result.category_list;
				}
			}
		}
	};
</script>

<style lang="less" scoped>
	.banner {
		height: 512px;
		min-width: 1200px;

		img {
			height: 512px;
			width: 100%;
			min-width: 1200px;
		}
	}
</style>
