// 右边栏

<template>
  <div class="rightCot">
    <div class="topCot">
      <!-- 患者信息 -->
      <div class="patInfo">
        <div class="title">
          <span class="shu"></span>
          <span>患者信息</span>
          <div class="fr">
            <span @click="open('selDialog')">选择</span>
            <span @click="open('editDialog')">编辑</span>
          </div>
        </div>
        <div class="content">
          <el-row>
            <el-col :span="12">
              <span class="space">姓名</span>
              <span>{{ inquiryInfo.realname ? inquiryInfo.realname : "无" }}</span>
            </el-col>
            <el-col :span="12">
              <span class="space">性别</span>
              <span>{{ inquiryInfo.sex == 0 ? "保密" : inquiryInfo.sex == 1 ? '男' : '女'}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span class="space">年龄</span>
              <span>{{ inquiryInfo.age ? inquiryInfo.age : "无" }}</span>
            </el-col>
            <el-col :span="12">
              <span class="space">联系电话</span>
              <span>{{ inquiryInfo.mobile ? inquiryInfo.mobile : "无" }}</span>
            </el-col>
          </el-row>
          <el-row>
            <span class="space">过敏史</span>
            <span>{{ inquiryInfo.allergy ? inquiryInfo.allergy : "无" }}</span>
          </el-row>
          <el-row>
            <span class="space">遗传史</span>
            <span>{{ inquiryInfo.genetic_disease ? inquiryInfo.genetic_disease : "无" }}</span>
          </el-row>
        </div>
      </div>
    </div>
    <div class="botCot">
      <div class="zhuanjie">
        <span class="shu"></span>
        <span>转接医生</span>
      </div>
      <button @click="transferDoctor">转接医生</button>
    </div>
    <div v-show="isShow" class="Mask">
      <!-- 选择弹出框 -->
      <div v-show="selDialog" class="selDialog">
        <div class="sel">
          <span>选择患者档案</span>
          <img
            src="@/assets/image/Interrogation_Drugs_delete@2x.png"
            @click="close('selDialog')"
            alt
          />
        </div>
        <div class="content">
          <div class="option" v-for="(v, i) in patientList" :key="i">
            <el-radio v-model="archives" :label="i + 1">{{ v.realname }}</el-radio>
            <span class="time">{{ v.add_time.split(" ")[0] }}</span>
          </div>
        </div>
        <button @click="selectPatientInfo()">保存</button>
      </div>
      <!-- 编辑弹出框 -->
      <div v-show="editDialog" class="editDialog">
        <div class="sel">
          <span>编辑患者信息</span>
          <img
            src="@/assets/image/Interrogation_Drugs_delete@2x.png"
            @click="close('editDialog')"
            alt
          />
        </div>
        <div class="content">
          <table>
            <tr>
              <td>
                <span>姓名</span>
                <input
                  type="text"
                  :placeholder="inquiryInfo.realname"
                  v-model="inquiryInfo.realname"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>性别</span>
                <input
                  type="radio"
                  name="sex"
                  :checked="inquiryInfo.sex === 1"
                  @click="inquiryInfo.sex = 1"
                /> 男
                <input
                  type="radio"
                  name="sex"
                  :checked="inquiryInfo.sex === 2"
                  @click="inquiryInfo.sex = 2"
                /> 女
              </td>
            </tr>
            <tr>
              <td>
                <span>年龄</span>
                <input type="text" :placeholder="inquiryInfo.age" v-model="inquiryInfo.age" />
              </td>
            </tr>
            <tr>
              <td>
                <span>联系电话</span>
                <input type="text" :placeholder="inquiryInfo.mobile" v-model="inquiryInfo.mobile" />
              </td>
            </tr>
            <tr>
              <td>
                <span>过敏史</span>
                <input type="text" :placeholder="inquiryInfo.allergy" v-model="inquiryInfo.allergy" />
              </td>
            </tr>
            <tr>
              <td>
                <span>遗传史</span>
                <input
                  type="text"
                  :placeholder="inquiryInfo.genetic_disease"
                  v-model="inquiryInfo.genetic_disease"
                />
              </td>
            </tr>
          </table>
        </div>
        <button @click="editPatientInfo">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getPatientList } from "@/request/personnel";
export default {
  props: ["inquiryInfo"],
  data() {
    return {
      isShow: false, //遮罩层显示
      selDialog: false,
      editDialog: false,
      resultDialog: false,
      addGoodDialog: false,
      checked: false,
      checked2: false,
      checked3: false,
      archives: 1,
      num: 0,
      alldepartment: {},
      patientList: []
    };
  },
  created() {
     getPatientList({
        token: this.$token,
        inquiry_id: this.inquiryInfo.id
      }).then(res => {
        this.patientList = res.data.data;
      });
  },
  computed: {
    department1() {
      return this.$store.state.department1;
    },
    department2() {
      return this.$store.state.department2;
    }
  },
  watch: {
    department1() {
      this.renderDom(this.department1, 0);
    },
    department2() {
      this.renderDom(this.department2, 1);
    }
  },
  methods: {
    close(e) {
      this[e] = false;
      this.isShow = false;
    },
    open(e) {
      this[e] = true;
      this.isShow = true;
    },
    // 转接医生
    transferDoctor() {
      let obj = {
        method: "chat_transfer_category"
      };
      this.$store.state.sendMessage(JSON.stringify(obj));
    },
    // 获取具体科室信息
    getDepartment(id) {
      let obj = {
        method: "chat_transfer_category",
        data: {
          pid: id
        }
      };
      this.$store.state.sendMessage(JSON.stringify(obj));
    },
    // 获取可以转接的医生
    getOnlineDoctor(id) {
      let obj = {
        method: "get_doctor_list",
        data: {
          inquiry_id: this.inquiryInfo.id,
          category_id: id
        }
      };
      this.$store.state.sendMessage(JSON.stringify(obj));
    },
    renderDom(department, num) {
      let self = this;
      let view = document.getElementsByClassName("view")[1];
      let div = document.createElement("div");
      let ps = "";
      department.forEach(item => {
        ps += "<p id=" + item.id + ">" + item.category_name + "</p>";
      });
      div.innerHTML = `<div> <img  src="${"https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1649554161,3942481746&fm=115&gp=0.jpg"}"/>
                           <span>  
                              请选择要转接的科室
                               ${ps}
                           </span> 
                        </div>`;
      div.className = "transfer chatbox";
      view.appendChild(div);
      view.scrollTop = view.scrollHeight;
      if (num == 0) {
        div.onclick = function(e) {
          let id = e.target.getAttribute("id");
          if (id) self.getDepartment(+id);
        };
      } else if (num == 1) {
        div.onclick = function(e) {
          let id = e.target.getAttribute("id");
          if (id) self.getOnlineDoctor(+id);
        };
      }
    },
    editPatientInfo() {
      // 保存患者信息
      this.isShow = false;
      this.editDialog = false;
    },
    selectPatientInfo() {
      if (this.patientList.length == 0) return;
      let v = this.patientList.slice(this.archives - 1, this.archives)[0];
      // this.inquiryInfo.age = v.age;
      this.inquiryInfo.sex = v.sex;
      this.inquiryInfo.allergy = v.allergy;
      this.inquiryInfo.genetic_disease = v.genetic_disease;
      this.disease_desc = v.disease_desc;
      this.inquiryInfo.mobile = v.mobile;
      this.inquiryInfo.realname = v.realname;
      this.inquiryInfo.height = v.height;
      this.inquiryInfo.add_time = v.add_time;
      this.inquiryInfo.birthday = v.birthday;
      this.isShow = false;
      this.selDialog = false;
    }
  }
};
</script>

<style  lang="less" scoped>
.rightCot {
  height: 100%;
  width: 37.5%;
  position: absolute;
  min-width: 450px;
  right: 0;
}
.topCot {
  overflow: hidden;
  .title {
    margin-top: 10px;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
    .shu {
      display: inline-block;
      width: 4px;
      height: 20px;
      background-color: #38eec1;
      float: left;
      margin-top: 6px;
      margin-right: 8px;
    }
    .fr {
      font-size: 16px;
      color: #38eec1;
      span {
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
  .patInfo {
    margin-bottom: 15px;
    .content {
      margin-left: 15px;
      margin-top: 10px;
      .space {
        display: inline-block;
        width: 70px;
        text-align-last: justify;
        margin-right: 10px;
      }
    }
  }
  .result {
    .content {
      textarea {
        width: 100%;
        border: none;
        padding: 15px;
        font-size: 18px;
      }
    }
  }
  .drugs {
    margin-bottom: 15px;
    .drugInfo {
      padding-left: 10px;
      font-size: 14px;
      .el-input-number {
        float: right;
      }
      .explain {
        margin: 0 12px;
      }
      .el-input {
        display: inline-block;
      }
      .title {
        margin-bottom: 20px;
      }
      .grid-content.bg-purple.left > div {
        margin-top: 10px;
        margin-left: 8px;
      }
      .drugInfo .el-row:nth-child(1) {
        line-height: 40px;
      }
      .grid-content.bg-purple.num span {
        margin-left: 50px;
      }
      .grid-content.bg-purple.del {
        color: red;
        cursor: pointer;
      }
      .name {
        img {
          vertical-align: -10px;
          margin-right: 15px;
        }
      }
    }
  }
}
.botCot {
  width: 100%;
  border-top: 1px solid #38eec1;
  height: 200px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  button {
    padding: 10px 60px;
    background: #38eec1;
    border-radius: 12px;
    position: absolute;
    top: 80px;
    margin-left: 180px;
    cursor: pointer;
  }
  .zhuanjie {
    float: left;
    margin-top: -30px;
    margin-left: 10px;
    .shu {
      display: inline-block;
      width: 4px;
      height: 20px;
      background-color: #38eec1;
      float: left;
      margin-top: 2px;
      margin-right: 8px;
    }
  }
}
.Mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.5);
  .selDialog {
    width: 600px;
    height: 400px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      .option {
        display: inline-block;
        width: 40%;
        line-height: 35px;
        margin-left: 20px;
        .el-radio {
          margin-left: 20px;
          width: 55px;
        }
        span {
          color: #666;
        }
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .editDialog {
    width: 600px;
    height: 400px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      table {
        width: 100%;
        tr {
          span {
            display: inline-block;
            width: 70px;
            margin-right: 10px;
            text-align-last: justify;
          }
          height: 38px;
        }
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .resultDialog {
    width: 600px;
    height: 400px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      textarea {
        width: 100%;
        overflow-y: scroll;
        resize: none;
        outline: none;
        height: 230px;
        font-size: 20px;
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .addGoodDialog {
    width: 600px;
    height: 650px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      .jibie {
        height: 35px;
        select {
          height: 27px;
          width: 400px;
          margin-left: 14px;
        }
      }
      .zhuji {
        i {
          margin-top: 10px;
          margin-right: 14px;
        }
      }
      .name {
        border-top: 20px solid #ddd;
        margin-top: 20px;
        padding: 10px;
        span {
          margin-left: 12px;
        }
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
}
</style>