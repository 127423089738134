// 药师审方

<template>
  <div class="trial">
    <h3 class="title">
      <span>药师审方</span>
      <hr />
    </h3>
<!--    <template v-if="auditList.length == 0">
      <div class="none">目前没有要处理的审方</div>
    </template> -->
    <div class="wrap" v-for="(audit, i) in auditList" :key="i">
      <div class="user">{{ audit.add_username }}</div>
      <div class="type">
        处方类型:
        <span>{{ audit.type_text }}</span>
        <button @click="changeState(audit)">查看</button>
      </div>
      <div class="date">
        申请时间:
        <span>{{ audit.add_time }}</span>
      </div>
    </div>
    <div class="page" v-if="showPage">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="15"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
// 药师获取待审核处方列表
import { getWaitingAuditList } from "@/request/personnel";
export default {
  data() {
    return {
      auditList: [],
      showPage: false,
      total: 0
    };
  },
  created() {
    setTimeout(() => {
      this.getPageWaitingAuditList(1);
    }, 500);
  },
  methods: {
    getPageWaitingAuditList(n) {
      // 获取待审核处方列表
      getWaitingAuditList({
        token: this.$token,
        page: n
      }).then(res => {
        console.log(res);
        this.showPage = res.data.last_page > 1;
        this.total = res.data.total;
        this.auditList = res.data.data;
      });
    },
    changeState(audit) {
      // 查看处方单时讲处方单号传递过去
      this.$emit("changeState", 2, audit.p_code);
    },
    handleCurrentChange(n) {
      // val 表示当前页
      this.getPageWaitingAuditList(n);
    }
  }
};
</script>

<style lang="less" scoped>
.trial {
  width: 1200px;
  min-height: 600px;
  margin: 0 auto 100px;
  position: relative;
}
.trial .title {
  font-size: 22px;
  font-weight: 400;
  margin-top: 50px;
  margin-left: 35px;
  margin-bottom: 20px;
}
.trial .wrap {
  background: #fbfbfb;
  border: 1px solid #f5f5f5;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  font-size: 14px;
  button {
    width: 60px;
    height: 25px;
    outline: 0;
    cursor: pointer;
    float: right;
    margin-right: 40px;
    border-radius: 5px;
  }
}
.trial .wrap div {
  margin-top: 8px;
  color: #656565;
}
.trial .wrap div.user {
  color: #71c0d6;
  font-size: 20px;
  margin-bottom: 10px;
}
hr {
  border: none;
  width: 1000px;
  height: 2px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 5px;
  background-color: #f5f5f5 !important;
}
.page /deep/ {
  text-align: center;
  margin: 28px 0px;
}
.none {
  line-height: 500px;
  text-align: center;
  font-size: 28px;
}
</style>