<template>
  <div class="wrapper">
    <div class="view" ref="view" @click="growImg($event)"></div>
    <div class="select">
      <img @click="openUpload" src="@/assets/image/Customerservice_Sendpictures@2x.png" />
      <template>
        <el-upload
          v-show="imgUrl != ''"
          :action="imageUrl"
          list-type="picture-card"
          :on-success="UserImgSuccess"
          :on-remove="UserImgtRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </template>
    </div>
    <div class="input">
      <textarea
        :disabled="disabled"
        @keydown="entrySend($event)"
        ref="text"
        rows="5"
        name="S1"
        cols="20"
      />
      <button @click="sendText">发送</button>
      <!-- 根域名服务器再向 -->
    </div>
    <div class="mask" v-if="showImgWrap">
      <img :src="showImgUrl" alt />
      <i class="el-icon-close close" @click="closeImg"></i>
    </div>
  </div>
</template>

<script>
import { usergetinfo } from "@/request/user";

export default {
  props: ["userMessage"],
  data() {
    return {
      imgUrl: "",
      isShow: false,
      disabled: false,
      showImgUrl: "",
      showImgWrap: false,
      myAvatar: ""
    };
  },
  created() {
    usergetinfo({ token: this.$token }).then(res => {
      if (res.code == 1) {
        this.myAvatar = res.data.avatar;
      } else {
        this.myAvatar = require("@/assets/image/Interrogation_expert_Headportrait@2x.png");
      }
    });
  },
  watch: {
    userMessage() {
      this.userTransmitMessage();
    }
  },
  methods: {
    sendText() {
      if (this.imgUrl != "") {
        this.sendImg();
      }
      if (this.$refs.text.value.trim() == "") return;
      // 给父组件传递聊天对话信息
      this.$emit("receiveMessage", this.$refs.text.value);
      // 读取img图片的数据
      let div = document.createElement("div");
      div.innerHTML = `<div> <img  src="${this.myAvatar}"/>
                           <span> ${this.$refs.text.value} </span> </div>`;
      div.className = "doctor chatbox";
      this.$refs.view.appendChild(div);
      this.$refs.text.value = "";
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
    },
    sendImg() {
      this.$emit("receiveMessage", this.imgUrl);
      let div = document.createElement("div");
      div.innerHTML = `<div> <img  src="${this.myAvatar}"/>
                           <span class='img'> <img src='${this.imgUrl}'/> </span> </div>`;
      div.className = "doctor chatbox";
      this.$refs.view.appendChild(div);
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
      this.imgUrl = ''
    },
    entrySend(e) {
      if (this.$refs.text.value.trim() == "") return;
      if (e.keyCode === 13) {
        this.sendText();
      }
    },
    UserImgSuccess(response) {
      this.imgUrl = response.data.file.url;
    },
    UserImgtRemove() {
      this.imgUrl = "";
    },
    openUpload() {
      this.imgUrl = "";
      let div = document.getElementsByClassName("el-upload--picture-card")[0];
      div.click();
    },
    // 用户传递的信息
    userTransmitMessage() {
      let avatar;
      if (
        this.userMessage.result.user_info &&
        this.userMessage.result.user_info.avatar &&
        "http://apponline.sdlzyy.cn/upload/" !=
          this.userMessage.result.user_info.avatar
      ) {
        avatar = this.userMessage.result.user_info.avatar;
      } else {
        avatar = require("@/assets/image/user.png");
      }
      console.log(this.userMessage);
      // 传递的是图片
      if (
        this.userMessage.result.pic &&
        this.userMessage.result.pic.includes("http://hlwyaodian.jzbwlkj.com")
      ) {
        let div = document.createElement("div");
        div.innerHTML = `<div> <img  src="${avatar}"/>
                           <span class='img'> <img src='${this.userMessage.result.pic}'/> </span> </div>`;
        div.className = "user chatbox";
        this.$refs.view.appendChild(div);
      } else {
        // 传递的是文本信息
        let div = document.createElement("div");
        div.innerHTML = `<div> <img  src="${avatar}"/>
                           <span> ${this.userMessage.result.message} </span> </div>`;
        div.className = "user chatbox";
        this.$refs.view.appendChild(div);
      }
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
    },
    // 放大图片处理函数
    growImg(e) {
      this.main = document.querySelector(".view .main");
      if (e.target.nodeName === "IMG") {
        this.showImgWrap = true;
        this.showImgUrl = e.target.src;
        this.main.style.zIndex = "4";
      }
    },
    closeImg() {
      this.showImgUrl = "";
      this.showImgWrap = false;
      this.main.style.zIndex = "2";
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 62.5%;
  height: 100%;
  position: absolute;
  left: 0px;
  min-width: 750px;
}
.view {
  position: absolute;
  width: 100%;
  height: calc(100% - 150px - 70px);
  border-right: 1px solid #999;
  overflow: scroll;
}
.select {
  width: 100%;
  min-width: 750px;
  height: 40px;
  background: #ddd;
  position: absolute;
  border: 1px solid #999;
  z-index: 999;
  bottom: 200px;
  width: 100%;
  img {
    width: 22px;
    height: 18px;
    margin-top: 8px;
    margin-left: 20px;
    cursor: pointer;
  }
}
.select /deep/ .el-upload {
  position: absolute;
  top: -160px;
  left: 20px;
}
.select /deep/ .el-upload-list__item {
  position: absolute;
  top: -160px;
  left: 20px;
  z-index: 999;
}
.input {
  width: 100%;
  height: 200px;
  border: 1px solid #999;
  position: absolute;
  bottom: 0px;
  textarea {
    padding-top: 18px;
    width: 100%;
    height: 150px;
    font-size: 22px;
    border: 0px;
  }
  button {
    width: 70px;
    height: 35px;
    border-radius: 8px;
    position: absolute;
    top: 100px;
    right: 10px;
  }
}
.chatbox {
  width: 300px;
  font-size: 30px;
  float: right;
  overflow: hidden;
  text-align: right;
  span {
    float: right;
  }
  img {
    float: right;
  }
}
.mask {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  text-align: center;
  img {
    width: 40%;
    margin-top: 100px;
  }
  .close {
    font-size: 50px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>