// 审核不通过理由

<template>
  <div>
    <div class="title">
      <span>药师建议</span>
      <hr />
    </div>
    <div class="content">
      <textarea placeholder="请仔细填写不通过的理由" v-model="reason"></textarea>
    </div>
    <div class="button">
      <button @click="back">返回</button>
      <button @click="send">发送</button>
    </div>
  </div>
</template>

<script>
import { audit } from "@/request/personnel"
export default {
    props: ["pCode","imgUrl"],
    data() {
      return {
        reason: ""
      }
    },
    methods: {
        back() {
            this.$emit("changeState", 2, this.pCode)
        },
        send() {
			console.log(this.imgUrl);
            // 处理逻辑
            audit({
              token: this.$token,
              p_code: this.pCode,
              audit_status: 0,
			   audit_sign: this.imgUrl,
              note: this.reason              
            }).then(res => {
              console.log(res)
            })
            this.$emit("changeState", 1);
        }
    }
};
</script>

<style lang="less" scoped>
hr {
  border: none;
  width: 1000px;
  height: 2px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 5px;
  background-color: #f5f5f5 !important;
}
.title {
  font-size: 22px;
  font-weight: 400;
  margin-top: 50px;
  margin-left: 35px;
  margin-bottom: 20px;
}
.content {
  width: 1200px;
  height: 413px;
  background: #fbfbfb;
  textarea {
    width: 100%;
    height: 100%;
    resize: none;
    font-size: 18px;
    padding: 24px 19px;
    box-sizing: border-box;
    border: none;
    background: #fbfbfb;
  }
}
.button {
  text-align: center;
  margin: 30px 0px;
  button {
    cursor: pointer;
    width: 140px;
    height: 64px;
    color: #fff;
    font-size: 24px;
    margin: 0 20px;
  }
  button:nth-of-type(1) {
    background: rgba(153, 153, 153, 1);
  }
  button:nth-of-type(2) {
    background: rgba(56, 238, 193, 1);
  }
}
</style>