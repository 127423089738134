<template>
	<div class="disponseInfo">
		<div>
			<span>处方信息</span>
			<hr />
		</div>
		<!-- 非图片处方单:  -->
		<template v-if="disInfo && disInfo.type == 0">
			<div class="bill_wrap">
				<div class="bill">
					<div class="date">2019-10-23</div>
					<div class="invald">本处方单3个月内有效</div>
					<h3>处方笺</h3>
					<el-row>
						<el-col :span="12">
							科别:
							<span>{{ disInfo.inquiry_info.category_name }}</span>
						</el-col>
						<el-col :span="12">
							处方号:
							<span>{{ disInfo.inquiry_info.p_code }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="8">
							姓名:
							<span>{{ disInfo.inquiry_name }}</span>
						</el-col>
						<el-col :span="8">
							年龄:
							<span>{{ disInfo.inquiry_info.age }}</span>
						</el-col>
						<el-col :span="8">
							性别:
							<span>{{ disInfo.inquiry_info.sex === 0 ? "保密" : (disInfo.inquiry_info.sex === 1 ? "男" : "女") }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							过敏史:
							<span>{{ disInfo.inquiry_info.allergy }}</span>
						</el-col>
						<el-col :span="12">
							遗传史:
							<span>{{ disInfo.inquiry_info.genetic_disease }}</span>
						</el-col>
					</el-row>
					<el-row>
						临床诊断:
						<span>{{ disInfo.inquiry_info.diagnosis}}</span>
					</el-row>
					<div class="content">
						<div class="type">R</div>
						<div class="name" v-for="(item, i) in disInfo.goods_list" :key="i">
							<el-row>
								<el-col :span="8">{{ item.goods_name }}</el-col>
								<el-col :span="8">{{item.spec}}</el-col>
								<el-col :span="8">x{{item.num}} {{item.unit}}</el-col>
							</el-row>
							<el-row>
								<el-col :span="4">用法</el-col>
								<el-col :span="5">{{ item.drugRoute_name}}</el-col>
								<el-col :span="5">每次{{item.drugDosage}}{{item.drugUseUnit_name}}</el-col>
								<el-col :span="5">{{item.drugFrequency_name}}</el-col>
								<el-col :span="5">服用{{item.drugDay}}天</el-col>
							</el-row>
						</div>
					</div>

					<div class="graph">
						<el-row>
							<el-col :span="12">
								<p>医生签字:</p>
								<img :src="disInfo.sign_list.find((item) => item.cert_type == 1 || item.cert_type == 2).audit_sign" alt />
							</el-col>
							<el-col :span="12">
								<p>药师签字:</p>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</template>
		<!-- 图片处方单, 自己要添加的 -->
		<template>
			<div class="wrap">
				<div class="pre_img" v-if="disInfo.type==1||disInfo.type==2">
					<img :src="disInfo.pic" alt />
				</div>
				<div class="addDrug">
					<div class="title">
						<span>添加药品</span>
						<hr />
					</div>
					<div class="drugInfo">
						<div>
							<span>药品信息</span>
						</div>
						<div>
							<span>数量</span>
						</div>
						<div>
							<span>操作</span>
						</div>
					</div>
					<div class="content" v-for="(good, i) in addGoods" :key="i">
						<div class="info">
							<div class="title">
								<div class="shop">
									<img :src="good.goods_img" alt />
									<span>{{ good.goods_name }}</span>
								</div>
								<div class="num">
									<el-input-number v-model="good.num" :min="1" :max="100" @change="handleChange"></el-input-number>
								</div>
								<div class="del">
									<span @click="cancelGood(good)">删除</span>
								</div>
							</div>
							<div class="inputlist">
								<div class="inputbox">
									<label>医嘱类型 :</label>
									<div>
										<el-select v-model="good.orderCode" placeholder="请选择" @change="getCode(good.orderCode,exhortlist,i,'orderClassification')">
											<el-option v-for="item in exhortlist" :key="item.code" :label="item.name" :value="item.name">
											</el-option>
										</el-select>
									</div>
								</div>
								<div class="inputbox">
									<label>单次计量 :</label>
									<div>
										<el-input v-model="good.drugDosage" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div class="inputbox">
									<label>计量单位 :</label>
									<div>
										<el-select v-model="good.drugUseUnit_name" placeholder="请选择" @change="getCode(good.drugUseUnit_name,unitlist,i,'drugUseUnit')">
											<el-option v-for="item in unitlist" :key="item.code" :label="item.name" :value="item.name">
											</el-option>
										</el-select>
									</div>
								</div>
								<div class="inputbox">
									<label>用药途径 :</label>
									<div>
										<el-select v-model="good.drugRoute_name" placeholder="请选择" @change="getCode(good.drugRoute_name,channellist,i,'drugRoute')">
											<el-option v-for="item in channellist" :key="item.code" :label="item.name" :value="item.name">
											</el-option>
										</el-select>
									</div>
								</div>
								<div class="inputbox">
									<label>用药频率 :</label>
									<div>
										<el-select v-model="good.drugFrequency_name" placeholder="请选择" @change="getCode(good.drugFrequency_name,ratelist,i,'drugFrequency')">
											<el-option v-for="item in ratelist" :key="item.code" :label="item.name" :value="item.name">
											</el-option>
										</el-select>
									</div>
								</div>
								<div class="inputbox">
									<label>总剂量 :</label>
									<div>
										<el-input v-model="good.drugIntegraldose" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div class="inputbox">
									<label>用药天数 :</label>
									<div>
										<el-input v-model="good.drugDay" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div class="inputbox">
									<label>是否皮试 :</label>
									<div>
										<el-select v-model="good.skin" placeholder="请选择" @change="getCode(good.skin,skinlsit,i,'skinTest')">
											<el-option v-for="(item,index) in skinlsit" :key="item.code" :label="item.name" :value="item.name">
											</el-option>
										</el-select>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="onlineDrug">
					<img src="@/assets/image/Onlinedrugselection_plus@2x.png" alt />
					<span @click="open('addGoodDialog')">在线选药</span>
				</div>
			</div>
		</template>
		<div class="wrap_autograph">
			<span>审核意见</span>
			<hr />
			<textarea v-model="proposal" name="" id="" cols="30" rows="4"></textarea>  
		</div>
		<div class="wrap_autograph">
			<span>药师签名</span>
			<hr />
			<div v-if="noAutograph" class="autograph">
				<span @click="open('showGraph')">点击签名</span>
			</div>
			<div v-if="already">
				<img :src="imgUrl" alt />
			</div>
			<div id="align">
				<el-button type="info" @click="save('no')">审核不通过</el-button>
				<el-button type="primary" @click="save">审核通过</el-button>
			</div>
		</div>
		<div @mousewheel.prevent class="mask" v-if="isShow">
			<!-- 签名 -->
			<div v-show="showGraph" class="autograph">
				<div class="sel">
					<span>药师签名</span>
					<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="isShow = false" />
				</div>
				<div class="content">
					<Autograph @getImgUrl="getImgUrl" />
				</div>
			</div>
			<!-- 添加商品 -->
			<div v-show="addGoodDialog" class="addGoodDialog">
				<div class="sel">
					<span>添加商品</span>
					<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('addGoodDialog')" />
				</div>
				<div class="content" v-if="drugItems">
					<div>
						<div class="jibie">
							<span>一级药品分类</span>
							<el-select v-model="class1value" placeholder="请选择" @change="changeTwoItems">
								<el-option v-for="(item,index) in drugItems" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="jibie">
							<span>二级药品分类</span>
							<el-select v-model="class2value" placeholder="请选择" @change="changeThreeItems">
								<el-option v-for="(item,index) in towDrugItems" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="jibie">
							<span>三级药品分类</span>
							<el-select v-model="class3value" placeholder="请选择" v-el-select-loadmore="loadmore">
								<el-option v-for="(item,index) in threeDrugItems" :key="item.value" :label="item.label" :value="item.value">

								</el-option>
							</el-select>
						</div>
					</div>
					<!-- 					<div class="zhuji">
						<i class="el-icon-search"></i>
						<input type="text" placeholder="请输入助记码" />
					</div> -->
					<div class="name">
						<div>
							<el-radio v-model="radio" label="1">
								<span>x1 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="2">
								<span>x2 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="3">
								<span>x3 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="4">
								<span>x4 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="5">
								<span>x5 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="6">
								<span>x6 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="7">
								<span>x7 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="8">
								<span>x8 （袋 / 盒）</span>
							</el-radio>
						</div>

						<div>
							<el-radio v-model="radio" label="9">
								<span>x9 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="10">
								<span>x10 （袋 / 盒）</span>
							</el-radio>
						</div>
					</div>
				</div>
				<button @click="sendAddGood">确认添加</button>
			</div>
		</div>
	</div>
</template>

<script>
	import Autograph from "@/components/autograph"; //签名图片
	import {
		getBedInfor
	} from "@/request/personnel";
	// 审核是否通过
	import {
		audit
	} from "@/request/personnel";
	// 获取处方单详情
	import {
		getInfo
	} from "@/request/personnel";
	// 获取药品列表
	import {
		getAllCate
	} from "@/request/personnel";
	// 获取药品详细信息
	import {
		getCateIndex
	} from "@/request/personnel";
	// 药师更新图片处方单
	import {
		picAdd
	} from "@/request/personnel";
	export default {
		components: {
			Autograph
		},
		props: ["pCode"],
		data() {
			return {
				isShow: false,
				imgUrl: "",
				noAutograph: true, // 已签名状态
				already: false, // 未签名状态
				disInfo: "",
				drugItems: [], // 药品各级分类
				towDrugItems:[],
				threeDrugItems:[],
				num: 1,
				radio: 0,
				showGraph: false, // 签名弹框
				addGoodDialog: false, // 添加商品弹框
				addGoods: [],
				skinlsit: [{
					code: "0",
					name: "不需要皮试"
				}, {
					code: "1",
					name: "需要皮试"
				}], //是否皮试
				skin: "",
				condition: "", //病情状况
				exhortlist: [], //嘱咐类型
				exhort: "",
				onlydose: "1", //单次剂量
				alldose: "1", //总剂量
				eatday: "1", //用药天数
				unitlist: [], //药品单位列表
				unit: "",
				channellist: [], //用药途径
				channel: '',
				ratelist: [], //用药频率
				rate: '',
				value: "",
				class1: "",
				class1value: "",
				class2value: "",
				class3value: "",
				page: 1,
				class3goodlist: [],
				proposal:"",
			};
		},
		directives: {
			'el-select-loadmore': {
				inserted(el, binding) {
					const SELECTWRAP_DOM = el.querySelector(
						'.el-select-dropdown .el-select-dropdown__wrap'
					);
					SELECTWRAP_DOM.addEventListener('scroll', function() {
						const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
						if (condition) {
							binding.value();
						}
					});
				}
			}
		},
		created() {
			// 医嘱项目分类
			getBedInfor({
				table: "ORDERCLASSIFICATION"
			}).then(res => {
				this.exhortlist = res.data;
			});
			//药品单位
			getBedInfor({
				table: "DRUGDOSAGEUNIT"
			}).then(res => {
				this.unitlist = res.data;
			});
			//用药途径
			getBedInfor({
				table: "DRUGROUTE"
			}).then(res => {
				console.log(res);
				this.channellist = res.data;
			});
			// 用药频率
			getBedInfor({
				table: "DRUGFREQUENCY"
			}).then(res => {
				this.ratelist = res.data;
			});
			getInfo({
				token: this.$token,
				p_code: this.pCode
			}).then(res => {
				this.disInfo = res.data;
				this.addGoods = [...this.disInfo.goods_list];
				console.log(this.disInfo);
			});
			// 获取1级分类
			getAllCate().then(res => {
				this.class1 = res.data;
				for (let i in res.data) {
					this.drugItems.push({
						value: i,
						label: res.data[i].category_name,
					})
				}
				console.log(this.class1);
			});
		},
		methods: {
			// 类型
			getCode(name, list, index, str) {
				console.log(name, list, index, str);
				for (let i in list) {
					if (list[i].name == name) {
						this.addGoods[index][str] = list[i].code
					}
				}
				console.log(this.addGoods);
			},
			open(e) {
				console.log(e);
				this[e] = true;
				this.isShow = true;
			},
			close(e) {
				this[e] = false;
				this.isShow = false;
				if (e == "autograph") {
					this.showGraph = false;
				}
			},
			getImgUrl(imgUrl) {
				this.imgUrl = imgUrl;
				this.noAutograph = false;
				this.already = true;
				this.isShow = false;
			},
			handleChange(num) {
				// 药品数量变化时触发
				this.num = num;
			},

			// 确认添加药品
			sendAddGood() {
				// 医生添加的商品
				// 从第三级数组中筛选出要选择的药品
				console.log(this.class3goodlist);
				let result = this.class3goodlist.find(
					item => item.id === this.class3value
				);

				let flag = false;
				for (let i of this.addGoods) {
					if (i.goods_id == result.id) {
						flag = true;
					} else {
						flag = false;
					}
				}
				if (flag) {
					this.$message({
						type: "error",
						message: "商品已经存在"
					});
				} else {
					console.log(this.exhort);
					this.addGoods.push({
						goods_name: result.goods_name,
						goods_img: result.goods_img,
						num: this.radio,
						goods_id: result.id,
						orderClassification: "",
						orderCode: this.exhort,
						drugFrequency: this.rate,
						drugDosage: this.onlydose,
						drugUseUnit: this.unit,
						drugIntegraldose: this.alldose,
						drugRoute: " ",
						skinTest: "",
						skin: this.skin,
						drugDay: this.eatday,
						unit: "袋/盒",
					});

					this.isAddDrug = true;
				}
				this.isShow = false;
				this.addGoodDialog = false;
			},
			cancelGood(good) {
				// 取消添加商品
				let index = this.addGoods.indexOf(good);
				if (index != -1) this.addGoods.splice(index, index + 1);
			},
			// 二级分类
			changeTwoItems(v) {
				this.towDrugItems = [];
				this.threeDrugItems = [];
				this.class2value = "";
				this.class3value = "";
				for (let i of this.class1[v].childData) {
					this.towDrugItems.push({
						value: i.id,
						label: i.category_name,
					})
				}
				console.log(this.threeDrugItems);
			},
			// 三级分类
			changeThreeItems(v) {
				this.page = 1;
				this.goodid = v;
				this.class3value = "";
				this.threeDrugItems = [];
				this.class3goodlist = [];
				getCateIndex({
					category_id: v,
					page: this.page,
				}).then(res => {
					this.page++;
					for (let i of res.data.data) {
						this.class3goodlist.push(i);
						this.threeDrugItems.push({
							value: i.id,
							label: i.goods_name,
						});
					}
				});
			},
			// 三级下拉加载
			loadmore() {
				getCateIndex({
					category_id: this.goodid,
					page: this.page,
				}).then(res => {
					this.page++;
					for (let i of res.data.data) {
						this.class3goodlist.push(i);
						this.threeDrugItems.push({
							value: i.id,
							label: i.goods_name,
						});
					}
				});
			},
			save(msg) {
				// 审核是否通过
				if (msg == "no") {
					this.$emit("changeState", 3, this.pCode, this.imgUrl);
				} else {
					if(this.proposal==""){
						this.$message({
							type: "error",
							message: "请输入审核意见"
						});
						return;
					}
					// 审核通过
					if (this.imgUrl === "") {
						this.$message({
							type: "error",
							message: "请签名后再提交"
						});
						return;
					}
					audit({
						token: this.$token,
						p_code: this.pCode,
						goods_list_json: this.addGoods,
						audit_status: 1,
						audit_opinion:this.proposal,
						audit_sign: this.imgUrl
					}).then(res => {
						if (res.code == 1) {
							this.$message.success("操作成功");
						}
					});
					this.$emit("changeState", 1);
				}
			},
			upPicAdd() {
				if (this.addGoods.length == 0) {
					this.$message.error('请选择药品')
					return;
				}
				picAdd({
					token: this.$token,
					audit_sign: this.imgUrl,
					goods_list_json: this.addGoods,
					p_code: this.pCode,
					audit_status: 1
				}).then(res => {
					if (res.code == 1) {
						this.$message.success("操作成功");
					}
				});
				this.$emit("changeState", 1);
			}
		}
	};
</script>

<style lang="less" scoped>
	.disponseInfo {
		width: 1200px;
		color: #333;
		margin: 0 auto;
	}

	.disponseInfo>div {
		padding: 30px;
	}

	// 单据信息
	.disponseInfo .bill_wrap {
		background: #fbfbfb;
	}

	// 单据信息
	.disponseInfo .bill {
		box-sizing: border-box;
		background: #fff;
		margin-left: 200px;
		box-shadow: 5px 5px 5px #ccc;
		width: 600px;
		min-height: 744px;
		font-size: 16px;
		border: 1px solid #ccc;
		padding: 20px;

		.date {
			margin-bottom: 5px;
		}

		h3 {
			font-size: 28px;
			font-weight: 500;
			text-align: center;
			margin: 10px 0px;
		}

		>.el-row {
			border-bottom: 1px solid #424242;
			line-height: 40px;
		}

		.content {
			min-height: 100px;
			padding: 10px 0px 15px 0px;

			.el-row {
				border: none;
				padding: 3px 0;

				.el-col {
					margin: 3px 0;
				}

				.el-col:nth-child(2) {
					text-align: center;
				}

				.el-col:nth-child(4) {
					text-indent: 2em;
				}

				.el-col:nth-child(5) {
					text-align: center;
				}

				.el-col:nth-child(3) {
					text-align: right;
				}

				.el-col:nth-child(6) {
					text-align: right;
				}

				.el-col {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.type {
				font-size: 22px;
				font-weight: 700;
			}

			.name {
				margin: 3px 0;
			}
		}

		.graph {
			border-top: 1px solid #424242;
			height: 150px;

			.el-row {
				margin-top: 12px;

				img {
					width: 100%;
				}
			}
		}
	}

	.disponseInfo .autograph {
		height: 100px;
		line-height: 100px;
		text-align: center;
		font-size: 13px;
		color: #999;
		margin-left: -25px;
		cursor: pointer;
	}

	.disponseInfo div.wrap_autograph {
		width: 1200px;
		margin-top: 15px;
		padding: 30px 0px;

		>span {
			margin-left: 30px;
		}
		textarea {
			width: 100%;
			margin-top: 20px;
			border-color: #E5E5E5;
			padding: 10px;
			font-size: 18px;
			resize:none;
		}
	}

	.disponseInfo div.wrap_autograph div {
		text-align: center;
	}

	.align {
		width: 1200px;
	}

	.disponseInfo div.wrap_autograph div button:nth-child(1) {
		margin-left: 50px;
	}

	.disponseInfo div.wrap_autograph div button:nth-child(2) {
		margin-left: 50px;
	}

	.el-button {
		width: 170px;
		height: 50px;
		font-size: 20px;
	}

	.el-button.el-button--primary {
		background-color: #38eec1;
		border: #38eec1;
	}

	.wrapper {
		/deep/ .el-dialog__footer {
			text-align: center;
		}

		/deep/ .el-dialog__body {
			padding: 10px 20px;

			.qianming {
				border: 1px solid #ccc;
				height: 200px;
				text-align: center;
			}
		}
	}

	.mask {
		position: fixed;
		left: 0px;
		top: 0px;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 3;

		.autograph {
			width: 604px;
			height: 370px;
			padding: 12px;
			position: absolute;
			left: 0px;
			top: 0px;
			bottom: 0px;
			right: 0px;
			margin: auto;
			background: #fff;
			text-align: left;

			.sel {
				line-height: 20px;
				color: #424242;
			}

			div {
				img {
					width: 15px;
					height: 15px;
					float: right;
					cursor: pointer;
				}
			}

			.content {
				margin: 20px 20px 20px;
				height: 240px;
				border: 1px solid #ccc;
			}
		}

		.addGoodDialog {
			width: 600px;
			height: 650px;
			padding: 12px;
			position: absolute;
			left: 0px;
			top: 0px;
			bottom: 0px;
			right: 0px;
			margin: auto;
			background: #fff;
			font-size: 18px;

			div {
				img {
					width: 15px;
					height: 15px;
					float: right;
					cursor: pointer;
				}
			}

			.content {
				padding: 40px 20px 20px;

			.jibie {
				height: 35px;
				display: flex;
				
				/deep/.el-select {
					height:30px;
					width: 400px;
					margin-left: 14px;
					border:1px solid #CCCCCC;
					border-radius: 5px;
					.el-input__inner{
						height: 27px;
						line-height: 27px;
						border-radius: 5px;
						
					}
					/deep/.el-input__icon{
						line-height: 27px !important;
					}
				}
				
			}

				.zhuji {
					i {
						margin-top: 10px;
						margin-right: 14px;
					}
				}

				.name {
					border-top: 20px solid #ddd;
					margin-top: 20px;
					padding: 10px;

					span {
						margin-left: 12px;
					}
				}
			}

			button {
				padding: 5px 25px;
				background: #fff;
				color: red;
				border: 1px solid red;
				border-radius: 12px;
				position: absolute;
				bottom: 20px;
				outline: 0;
				left: 260px;
				cursor: pointer;
			}
		}
	}

	hr {
		border: none;
		width: 960px;
		height: 2px;
		display: inline-block;
		margin-left: 10px;
		margin-bottom: 5px;
		background-color: #f5f5f5 !important;
	}

	.disponseInfo,
	.wrap_autograph {
		font-size: 22px;
	}

	.wrap_autograph .autograph {
		width: 1200px;
		height: 148px;
		background: #fbfbfb;
		margin: 20px 0 30px 0px;

		span {
			display: inline-block;
			margin-top: 30px;
		}
	}

	.wrap {
		padding: 0px !important;

		.addDrug .title {
			margin-left: 30px;
			margin-bottom: 25px;
		}

		.addDrug .drugInfo {
			width: 1200px;
			display: flex;
			height: 65px;
			background: #fbfbfb;
			line-height: 65px;
			margin-bottom: 16px;

			div:nth-of-type(1) {
				width: 700px;

				span {
					margin-left: 65px;
				}
			}

			div:nth-of-type(2) {
				width: 400px;

				span {
					margin-left: 230px;
				}
			}

			div:nth-of-type(3) {
				width: 300px;

				span {
					margin-left: 105px;
				}
			}
		}

		.addDrug .content {
			background: #fbfbfb;
			margin-bottom: 16px;

			.title {
				display: flex;
				justify-content: space-between;
				padding: 5px 10px;
				line-height: 40px;

				.shop {
					line-height: 70px;
				}

				img {
					width: 50px;
					height: 50px;
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
				}

				.num {
					margin-left: 456px;
				}

				.del {
					line-height: 70px;
					margin-right: 95px;
					color: red;
				}
			}

			.inputlist {
				display: flex;
				flex-wrap: wrap;

				.inputbox {
					width: 23%;
					display: flex;
					margin: 10px;

					label {
						width: 60%;
						margin-top: 3px;
					}
				}
			}
		}

		.pre_img {
			margin-bottom: 44px;
			height: 822px;
			background: rgba(251, 251, 251, 1);
			overflow: hidden;

			img {
				width: 984px;
				height: 695px;
				display: block;
				margin: 59px auto 68px;
			}
		}

		.onlineDrug {
			box-sizing: border-box;
			border: 1px dashed #cccccc;
			padding: 13px 0px;
			text-align: center;
			color: #999;

			img {
				width: 22px;
				height: 22px;
				vertical-align: -3px;
				cursor: pointer;
			}

			span {
				margin-left: 10px;
				cursor: pointer;
			}
		}
	}

	.num /deep/ .el-input-number {
		margin-top: 20px;
		width: 120px !important;

		span {
			width: 30px;
			height: 30px;
			background: #eee;
			line-height: 30px;
			z-index: 1;
			background: #fff;
		}

		.el-input {
			padding: 0px;
			background: #fff;
		}

		.el-input__inner {
			background: #fbfbfb;
		}
	}
</style>
